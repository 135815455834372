import { withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider } from '@wix/thunderbolt-symbols'

export const viewportHandlersProvider = withDependencies(
	[],
	(): SdkHandlersProvider<any> => {
		let observer: any = null
		const enterObservers: Record<string, Array<Function>> = {}
		const leaveObservers: Record<string, Array<Function>> = {}

		const initObserver = () => {
			const handler = (entries: Array<IntersectionObserverEntry>) => {
				entries.forEach((intersectionEntry) => {
					const isVisible = intersectionEntry.isIntersecting
					const compId = intersectionEntry.target.id
					if (isVisible && enterObservers[compId]) {
						enterObservers[compId].forEach((cb) => cb())
					}
					if (!isVisible && leaveObservers[compId]) {
						leaveObservers[compId].forEach((cb) => cb())
					}
				})
			}

			return new window.IntersectionObserver(handler)
		}

		const observeElement = (el: HTMLElement) => {
			observer = observer || initObserver()
			observer.observe(el)
		}

		return {
			getSdkHandlers() {
				return {
					onViewportEnter: (compId: string, cb: Function) => {
						if (process.env.browser) {
							const target = document.querySelector(`#${compId}`)
							if (target) {
								observeElement(target as HTMLElement)
								enterObservers[compId] = [...(enterObservers[compId] || []), cb]
							}
						}
					},
					onViewportLeave: (compId: string, cb: Function) => {
						if (process.env.browser) {
							const target = document.querySelector(`#${compId}`)
							if (target) {
								observeElement(target as HTMLElement)
								leaveObservers[compId] = [...(leaveObservers[compId] || []), cb]
							}
						}
					}
				}
			}
		}
	}
)
